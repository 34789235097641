import React from 'react';
import HK from 'factory/modules/HK';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from '../../../static/css/header.module.scss';
const cartMobile = (props) => {
  return (
    <div {...props}
      className={convertToModule(styles, "header-sprite header-sprite-cart cart Proceed_to_cart_header right-menu-item")}>
      {props.cartvalue && (
        <span
          id="semiBolditalic_H" className={(props.animatecart ? "semi_bolditalic cart-item-count-flip" : "semi_bolditalic")
          }
        >
          {props.cartvalue.noItemsInCart}
        </span>
      )}
      <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="25.445" height="27.544" viewBox="0 0 25.445 27.544"><defs><style></style><clipPath id="a1"><path class="a1" fill="none" clip-rule="evenodd" d="M1829.237-4875.525a8.237,8.237,0,0,0,8.238-8.237,8.237,8.237,0,0,0-8.238-8.237,8.237,8.237,0,0,0-8.237,8.237A8.237,8.237,0,0,0,1829.237-4875.525Z" transform="translate(-1821 4892)" /></clipPath><clipPath id="b1"><path class="b1" fill="none" d="M0,870.591H2259.4V-4925H0Z" transform="translate(0 4925)" /></clipPath></defs><g transform="translate(0 2.825)"><path class="c1" fill="#1a1a1a" d="M49.739,80.559c-2.826,0-5.039-2.654-5.039-6.039a.763.763,0,0,1,.643-.808h.078a.763.763,0,0,1,.643.808c.058,2.5,1.6,4.449,3.5,4.449h.351c1.861,0,3.392-1.91,3.5-4.359v-.1a.763.763,0,0,1,.643-.808h.078c.341,0,.624.346.643.808C54.777,77.905,52.565,80.559,49.739,80.559Z" transform="translate(-38.969 -64.251)" /><path class="c1" fill="#1a1a1a" d="M20.759,24.718H.737A.791.791,0,0,1,0,23.911V5a.813.813,0,0,1,.212-.551l4-4.205A.788.788,0,0,1,4.771,0h12a.814.814,0,0,1,.57.244l3.989,4.205A.881.881,0,0,1,21.552,5V23.911a.8.8,0,0,1-.737.808ZM1.564,23.141H19.977V5.795H1.564ZM2.626,4.205h16.3L16.435,1.59H5.106Z" transform="translate(0 0)" /></g><g class="d1" clip-path="url(#a1)" transform="translate(8.97)"><g class="e1" clip-path="url(#b1)" transform="translate(-2142.9 -38.833)"><path class="f1" fill="#fdb53d" d="M1816-4897h28.243v28.242H1816Z" transform="translate(321.016 4929.95)" /></g></g></svg>
    </div>
  );
};
const cartDesk = (props) => {
  return (
    <React.Fragment>
      <div className="header-sprite header-sprite-cart cart Proceed_to_cart_header desktop-cart-icon">
        {props.cartvalue && (
          <span
            id="semiBolditalic_H" className={(props.animatecart ? "semi_bolditalic cart-item-count-flip" : "semi_bolditalic")
            }
          >
            {props.cartvalue.noItemsInCart}
          </span>
        )}
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><path d="M6.917,7.833H8.448l2.295,10.355a3.289,3.289,0,0,0-1.871,3.042,3.133,3.133,0,0,0,2.962,3.275H23.988a.917.917,0,1,0,0-1.833H11.833A1.332,1.332,0,0,1,10.7,21.231a1.332,1.332,0,0,1,1.128-1.442H23.988a.917.917,0,0,0,.863-.606l3.1-8.591a.917.917,0,0,0-.863-1.227H10.665l-.586-2.646A.916.916,0,0,0,9.184,6H6.917a.917.917,0,0,0,0,1.833ZM25.779,11.2l-2.435,6.758H12.568l-1.5-6.758H25.779Z" transform="translate(-6 -6)" /><path d="M19.261,50a.917.917,0,0,0,0,1.833h1.164a.917.917,0,1,0,0-1.833Z" transform="translate(-12.687 -29.833)" /><path d="M37.713,50a.917.917,0,0,0,0,1.833h1.164a.917.917,0,1,0,0-1.833Z" transform="translate(-22.682 -29.833)" /></svg>
      </div>
    </React.Fragment>
  );
};
const renderCartIcon = (props) => {
  if (HK.isMobile()) {
    return cartMobile(props);
  } else {
    return cartDesk(props);
  }
};
export const Logo = () => {
  return (
    <svg version="1.1" id="svg2" width="119" height="30" viewBox="0 0 480 118.51827">
      <defs id="defs6">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath16">
          <path d="M 0,88.889 H 360 V 0 H 0 Z" id="path14"></path>
        </clipPath>
      </defs>
      <g id="g8" transform="matrix(1.3333333,0,0,-1.3333333,0,118.51827)">
        <g id="g10">
          <g id="g12" clip-path="url(#clipPath16)">
            <g id="g18" transform="translate(39.7767,67.9208)">
              <path d="m 0,0 c -2.89,-1.293 -5.159,-2.659 -6.756,-4.048 -1.582,-1.382 -3.004,-3.013 -4.242,-4.841 -1.228,-1.81 -2.28,-3.814 -3.136,-5.972 -0.865,-2.157 -1.45,-4.546 -1.733,-7.079 l -0.317,-2.964 c -0.446,-4.035 -0.209,-7.852 0.708,-11.336 0.96,-3.577 2.564,-6.381 4.795,-8.326 2.189,-1.906 4.036,-3.091 5.621,-3.657 1.468,-0.511 2.641,-0.874 3.469,-1.083 0.889,-0.222 1.69,-0.367 2.404,-0.434 0.634,-0.071 1.412,-0.16 2.496,-0.305 l 29.407,0.129 3.534,31.885 -28.915,-0.129 -1.25,-11.3 14.919,0.068 -1.003,-9.021 -11.472,-0.044 c -4.441,-0.101 -7.215,1.105 -8.412,3.346 -1.333,2.457 -1.751,5.999 -1.243,10.539 l 0.086,0.757 c 0.311,2.801 0.948,5.369 1.905,7.637 0.911,2.158 2.271,3.894 4.057,5.149 1.748,1.247 4.152,1.884 7.144,1.903 L 37.25,-9.016 38.481,2.139 13.008,2.031 C 7.233,2.004 2.986,1.342 0,0" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path20"></path>
            </g>
            <g id="g22" transform="translate(118.2807,54.0385)">
              <path d="M 0,0 C -0.166,-1.524 -0.643,-2.681 -1.348,-3.367 -2.318,-4.18 -3.395,-4.7 -4.537,-4.897 -5.83,-5.116 -7.483,-5.236 -9.456,-5.248 l -12.306,-0.046 1.106,9.972 12.601,0.059 C -5.014,4.792 -2.81,4.423 -1.314,3.78 -0.85,3.583 0.342,3.056 0,0 m 10.262,12.112 c -1.598,1.616 -3.41,2.653 -5.411,3.078 -1.822,0.391 -3.494,0.662 -4.965,0.8 -1.459,0.132 -2.896,0.176 -4.377,0.194 l -28.665,-0.126 -5.768,-52.061 13.774,0.061 2.136,19.345 11.216,0.053 9.656,-19.296 15.43,0.067 -11.069,21.106 c 2.025,0.613 3.771,1.462 5.211,2.555 1.807,1.37 3.3,3.13 4.457,5.245 1.019,2.013 1.668,4.291 1.942,6.772 0.256,2.234 0.154,4.453 -0.307,6.679 -0.594,2.08 -1.69,3.94 -3.26,5.528" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path24"></path>
            </g>
            <g id="g26" transform="translate(135.0172,18.2809)">
              <path d="M 0,0 14.294,0.071 20.059,52.12 5.765,52.061 Z" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path28"></path>
            </g>
            <g id="g30" transform="translate(206.7307,70.4941)">
              <path d="m 0,0 -48.521,-0.212 -1.225,-11.155 17.064,0.077 -4.516,-40.771 14.221,0.07 4.515,40.759 17.222,0.077 z" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path32"></path>
            </g>
            <g id="g34" transform="translate(207.1368,59.2098)">
              <path d="m 0,0 28.635,0.12 -31.959,-30.112 -1.179,-10.638 47.472,0.219 1.24,11.293 -28.068,-0.126 31.402,30.121 1.194,10.761 -47.469,-0.209 z" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path36"></path>
            </g>
            <g id="g38" transform="translate(298.9656,45.0374)">
              <path d="m 0,0 c -0.283,-2.481 -0.874,-4.866 -1.791,-7.163 -1.438,-4.222 -4.1,-6.913 -8.086,-8.215 -1.721,-0.477 -3.534,-0.754 -5.331,-0.729 -1.838,-0.013 -4.088,0.434 -6.692,1.308 -2.299,0.773 -3.65,2.632 -4.149,5.697 -0.557,3.42 -0.686,6.409 -0.419,8.899 0.28,2.533 0.825,4.869 1.619,6.941 1.958,4.404 4.704,7.122 8.317,8.292 1.711,0.489 3.46,0.732 5.174,0.742 0.988,-0.019 1.816,-0.062 2.672,-0.179 0.889,-0.138 1.865,-0.357 2.896,-0.665 0.942,-0.289 1.816,-0.809 2.604,-1.536 0.822,-0.748 1.438,-1.529 1.841,-2.32 0.52,-1.078 0.717,-1.607 0.785,-1.86 C -0.443,8.775 -0.255,7.75 0.028,6.134 0.289,4.586 0.283,2.533 0,0 m 9.486,20.653 c -1.065,1.21 -2.348,2.213 -3.807,2.986 -1.407,0.745 -2.502,1.24 -3.331,1.487 -0.757,0.237 -2.16,0.594 -4.201,1.077 -2.087,0.492 -4.875,0.73 -8.511,0.714 -1.89,-0.052 -3.718,-0.086 -5.251,-0.228 -1.496,-0.154 -3.228,-0.461 -5.121,-0.911 -1.915,-0.455 -3.805,-1.117 -5.618,-1.97 -1.81,-0.858 -3.844,-2.207 -6.039,-4.016 -2.262,-1.872 -4.232,-4.753 -5.845,-8.56 -1.502,-3.5 -2.499,-7.255 -2.927,-11.183 -0.453,-3.998 -0.397,-7.439 0.145,-10.219 0.557,-2.804 1.206,-4.946 2.016,-6.565 0.809,-1.653 1.871,-3.106 3.164,-4.309 1.259,-1.201 2.564,-2.152 3.887,-2.838 1.293,-0.671 2.349,-1.154 3.238,-1.475 0.881,-0.307 1.856,-0.566 2.928,-0.751 3.047,-0.68 6.3,-1.031 9.658,-1.018 h 0.096 c 3.367,0.015 6.95,0.434 10.628,1.249 3.774,0.831 7.206,2.453 10.197,4.823 3.002,2.389 5.344,5.566 6.969,9.447 1.591,3.801 2.61,7.658 3.035,11.481 0.419,3.881 0.255,7.732 -0.502,11.447 -0.788,3.832 -2.431,6.993 -4.808,9.332" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path40"></path>
            </g>
            <g id="g42" transform="translate(320.6846,72.1735)">
              <path d="m 0,0 c -2.715,0 -4.924,-2.209 -4.924,-4.924 0,-2.716 2.209,-4.925 4.924,-4.925 2.715,0 4.924,2.209 4.924,4.925 C 4.924,-2.209 2.715,0 0,0 m 0,-10.541 c -3.097,0 -5.617,2.52 -5.617,5.617 0,3.097 2.52,5.616 5.617,5.616 3.097,0 5.617,-2.519 5.617,-5.616 0,-3.097 -2.52,-5.617 -5.617,-5.617" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path44"></path>
            </g>
            <g id="g46" transform="translate(319.7005,67.3955)">
              <path d="m 0,0 h 1.056 c 0.347,0 0.618,0.087 0.813,0.262 0.195,0.175 0.293,0.414 0.293,0.717 0,0.317 -0.09,0.562 -0.271,0.736 C 1.711,1.888 1.442,1.977 1.085,1.983 H 0 Z M 1.146,-0.829 H 0 v -2.275 h -1.028 v 5.916 h 2.08 c 0.683,0 1.21,-0.153 1.581,-0.46 C 3.004,2.046 3.19,1.603 3.19,1.022 3.19,0.627 3.094,0.295 2.903,0.028 2.712,-0.239 2.446,-0.444 2.105,-0.588 L 3.433,-3.052 V -3.104 H 2.332 Z" style="fill:#292960;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path48"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
};
export const CartIcon = (props) => {
  return (
    renderCartIcon(props)
  );
};
export const UserIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"><path d="M23.144,19.823l-4.2-1.723a.594.594,0,0,1-.375-.548V16.57a.557.557,0,0,1,.119-.349,8.265,8.265,0,0,0,1.721-5.03C20.41,8.086,18.437,6,15.5,6s-4.91,2.086-4.91,5.192a8.266,8.266,0,0,0,1.721,5.03.557.557,0,0,1,.119.348v.981a.594.594,0,0,1-.375.548l-4.2,1.723A2.97,2.97,0,0,0,6,22.575v1.634A.792.792,0,0,0,6.792,25H24.208A.792.792,0,0,0,25,24.208V22.575A2.97,2.97,0,0,0,23.144,19.823Zm.273,3.594H7.583v-.842a1.392,1.392,0,0,1,.874-1.287l4.2-1.723a2.172,2.172,0,0,0,1.357-2.013V16.57a2.145,2.145,0,0,0-.468-1.34,6.729,6.729,0,0,1-1.372-4.038c0-2.66,1.718-3.608,3.327-3.608s3.327.948,3.327,3.608a6.729,6.729,0,0,1-1.372,4.038,2.146,2.146,0,0,0-.468,1.34v.981a2.172,2.172,0,0,0,1.357,2.013l4.2,1.723a1.392,1.392,0,0,1,.874,1.287Z" transform="translate(-6 -6)" /></svg>
  );
};
export const SearchIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15.691" height="16" viewBox="0 0 15.691 15.694"><path d="M12.715,19.425a6.7,6.7,0,0,0,4.18-1.46L20.4,21.472A.757.757,0,1,0,21.473,20.4l-3.507-3.507a6.716,6.716,0,1,0-5.251,2.531ZM9.038,9.037a5.2,5.2,0,1,1,0,7.354A5.165,5.165,0,0,1,9.038,9.037Z" transform="translate(-6.004 -6)" /></svg>
  );
};
export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.002" height="16" viewBox="0 0 16.002 16"><path d="M23.531,8.213a1.159,1.159,0,0,0-1.641,0l-6.018,6.018L9.854,8.213A1.161,1.161,0,0,0,8.213,9.854l6.018,6.018L8.213,21.89a1.161,1.161,0,0,0,1.645,1.638l6.014-6.014,6.018,6.018a1.161,1.161,0,1,0,1.645-1.638l-6.021-6.021,6.018-6.018A1.165,1.165,0,0,0,23.531,8.213Z" transform="translate(-7.873 -7.872)" /></svg>
  );
};
export const BulkingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74.222" height="69.585" viewBox="0 0 74.222 69.585"><path d="M34.938,37.738V15.993H9.4A123.215,123.215,0,0,0,0,63.333V76.273l2.826.942a164.8,164.8,0,0,0,51.827,8.363A19.58,19.58,0,0,0,59.2,46.952a19.806,19.806,0,0,0-19.561,6.376,17.465,17.465,0,0,0-9.055-2.542,17.849,17.849,0,0,0-8.845,2.35c0-4.874,0-11.887,0-15.4H34.938ZM21.12,59.055a13.3,13.3,0,0,1,9.469-3.92,12.9,12.9,0,0,1,6.6,1.788,19.124,19.124,0,0,0-1.78,4.8l4.244.949A15.407,15.407,0,0,1,58.243,51.194a15.231,15.231,0,0,1-3.589,30.035,161.49,161.49,0,0,1-50.3-8.091v-9.8a118.886,118.886,0,0,1,8.005-42.991H30.588v4.349H21.745V29.04h8.843v4.349H17.408c0,9.833.009-18.925-.011,29.48Z" transform="translate(0 -15.993)" /></svg>
  );
};
export const DoumbleIcon = () => {
  return (
    <svg className="performance" xmlns="http://www.w3.org/2000/svg" width="77.986" height="48.214" viewBox="0 0 77.986 48.214"><defs></defs><path className="a" d="M76.986,122.607a6.775,6.775,0,0,0-6.015-6.724v-9.215a2.255,2.255,0,0,0-2.255-2.255H61.949v-3.158A2.255,2.255,0,0,0,59.694,99H50.672a2.255,2.255,0,0,0-2.255,2.255v14.585H28.569V101.255A2.255,2.255,0,0,0,26.313,99H17.292a2.255,2.255,0,0,0-2.255,2.255v3.158H8.27a2.255,2.255,0,0,0-2.255,2.255v9.215a6.765,6.765,0,0,0,0,13.447v9.215A2.255,2.255,0,0,0,8.27,140.8h6.766v3.158a2.255,2.255,0,0,0,2.255,2.255h9.022a2.255,2.255,0,0,0,2.255-2.255V129.373H48.417v14.585a2.255,2.255,0,0,0,2.255,2.255h9.022a2.256,2.256,0,0,0,2.255-2.255V140.8h6.766a2.255,2.255,0,0,0,2.255-2.255v-9.215A6.775,6.775,0,0,0,76.986,122.607ZM6.015,120.483v4.248A2.254,2.254,0,0,1,6.015,120.483Zm4.511,15.807V108.924h4.511V136.29ZM24.058,141.7H19.547V103.511h4.511Zm4.511-16.841v-4.511H48.417v4.511ZM57.439,141.7H52.928V103.511h4.511Zm9.022-5.413H61.949V108.924H66.46Zm4.511-11.559v-4.248A2.254,2.254,0,0,1,70.971,124.731Z" transform="translate(0.5 -98.5)" /></svg>
  );
};
export const MassIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60.11" height="62.217" viewBox="0 0 60.11 62.217"><path d="M67.226.02a1.822,1.822,0,0,0-2.073,1.531l-.539,3.594a2.332,2.332,0,0,1-2.321,2H51.237a8.612,8.612,0,0,1-7.2-3.862A1.822,1.822,0,0,0,41,5.293a12.249,12.249,0,0,0,10.235,5.494H61.409c-3.8,29.662-3.606,27.869-3.533,28.265l1.418,8.569H50.686l2.036-2a1.822,1.822,0,1,0-2.554-2.6l-4.685,4.6H31.964l-4.685-4.6a1.822,1.822,0,1,0-2.554,2.6l2.036,2H18.155c1.543-9.329,1.485-8.759,1.429-9.146L16.039,10.786H26.211A12.249,12.249,0,0,0,36.446,5.292a1.822,1.822,0,0,0-3.039-2.012,8.611,8.611,0,0,1-7.2,3.862H15.155a2.332,2.332,0,0,1-2.321-2l-.539-3.594a1.823,1.823,0,0,0-3.605.54l.539,3.594a5.955,5.955,0,0,0,3.041,4.371l3.666,28.636L12.4,60.1a1.823,1.823,0,0,0,3.6.594l1.56-9.428H36.9v9.13a1.823,1.823,0,0,0,3.645,0v-9.13H59.9l1.56,9.428a1.823,1.823,0,0,0,3.6-.594l-3.541-21.4,3.666-28.636a5.955,5.955,0,0,0,3.041-4.371l.539-3.594A1.821,1.821,0,0,0,67.226.02Z" transform="translate(-8.669 0)" /><path d="M140.861,114.848l-.742-1.927a1.823,1.823,0,0,0-3.4,1.308l1.191,3.1a1.823,1.823,0,0,0,1.7,1.168h9.673v5.881h-6.038l-.742-1.928a1.823,1.823,0,0,0-3.4,1.308l1.191,3.1a1.823,1.823,0,0,0,1.7,1.168h7.29v6.119h-2.465l-.742-1.928a1.823,1.823,0,0,0-3.4,1.308l1.191,3.1a1.823,1.823,0,0,0,1.7,1.168h3.716v.916a1.823,1.823,0,0,0,3.645,0v-.916h3.716a1.823,1.823,0,0,0,1.7-1.168l1.191-3.1a1.823,1.823,0,0,0-3.4-1.308l-.742,1.928h-2.465v-6.119h7.29a1.823,1.823,0,0,0,1.7-1.168l1.191-3.1a1.823,1.823,0,0,0-3.4-1.308l-.742,1.928h-6.038v-5.881H162.6a1.823,1.823,0,0,0,1.7-1.168l1.191-3.1a1.823,1.823,0,0,0-3.4-1.308l-.742,1.927h-8.421v-3.3a1.823,1.823,0,0,0-3.645,0v3.3Z" transform="translate(-121.05 -96.395)" /><ellipse cx="1.823" cy="1.822" rx="1.823" ry="1.822" transform="translate(8.546 1.979)" /><ellipse cx="1.823" cy="1.822" rx="1.823" ry="1.822" transform="translate(47.918 1.979)" /></svg>
  );
};
export const WeightlossIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53.239" height="57.372" viewBox="0 0 53.239 57.372"><path d="M66.593,21.8a11.076,11.076,0,0,0-.934-10.269,18.877,18.877,0,0,1-2.79-9.847,1.681,1.681,0,1,0-3.362.063,22.24,22.24,0,0,0,3.279,11.53,7.805,7.805,0,0,1,.368,7.974,1.683,1.683,0,0,0,.164,1.77A28.169,28.169,0,0,1,65.361,26.1c-10.841,8.075-31.356,8.07-40.608-.009a28.16,28.16,0,0,1,2.038-3.074,1.683,1.683,0,0,0,.164-1.77,7.805,7.805,0,0,1,.368-7.974A22.243,22.243,0,0,0,30.6,1.683a1.681,1.681,0,1,0-3.362.063,18.877,18.877,0,0,1-2.79,9.783A11.076,11.076,0,0,0,23.516,21.8c-6.818,9.56-6.059,18.331-1.885,29.24a18.18,18.18,0,0,1,1.413,4.653,1.681,1.681,0,1,0,3.362,0c0-3.721-4.218-9.72-4.6-17.564,7.3,6.052,17.906,9.412,21.568,10.445v7.085a1.681,1.681,0,1,0,3.362,0V48.572C50.4,47.539,61,44.179,68.3,38.127c-.381,7.845-4.6,13.843-4.6,17.564a1.681,1.681,0,1,0,3.362,0,18.18,18.18,0,0,1,1.413-4.653C72.345,40.931,73.774,31.868,66.593,21.8ZM45.055,45.543c-2.491-.671-15.958-4.551-23.03-11.771a21.314,21.314,0,0,1,1.231-4.556c10.579,8.4,31.629,8.411,43.584-.032a21.322,21.322,0,0,1,1.244,4.589C61.013,40.992,47.546,44.872,45.055,45.543Z" transform="translate(-18.441 -0.001)" /><ellipse cx="1.838" cy="1.856" rx="1.838" ry="1.856" transform="translate(24.776 23.981)" /></svg>
  );
};
export const CloseBlack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.677" height="18.676" viewBox="0 0 18.677 18.676">
      <g id="Group_83921" data-name="Group 83921" transform="translate(-288.775 -22)">
        <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M9.34,7.458,16.4.392a1.335,1.335,0,1,1,1.885,1.89l-8,8.006a1.332,1.332,0,0,1-1.84.039L.389,2.288A1.335,1.335,0,0,1,2.274.4Z" transform="translate(307.452 22) rotate(90)" fill="#001d42" />
        <path id="Icon_ionic-ios-arrow-up-2" data-name="Icon ionic-ios-arrow-up" d="M9.34,3.219,16.4,10.285a1.335,1.335,0,0,0,1.885-1.89l-8-8.006A1.332,1.332,0,0,0,8.445.35L.389,8.389a1.335,1.335,0,0,0,1.885,1.89Z" transform="translate(299.452 22) rotate(90)" fill="#001d42" />
      </g>
    </svg>
  );
};
export const LeftArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.677" height="18.676" viewBox="0 0 10.677 18.676">
      <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M9.34,7.458,16.4.392a1.335,1.335,0,1,1,1.885,1.89l-8,8.006a1.332,1.332,0,0,1-1.84.039L.389,2.288A1.335,1.335,0,0,1,2.274.4Z" transform="translate(10.677) rotate(90)" fill="#001d42" />
    </svg>
  );
};
export const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7.367" height="12.887" viewBox="0 0 7.367 12.887">
      <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M12.633,13.471,17.5,18.347a.921.921,0,0,0,1.3-1.3l-5.52-5.524a.919.919,0,0,0-1.27-.027L6.456,17.039a.921.921,0,0,0,1.3,1.3Z" transform="translate(18.618 -6.188) rotate(90)" fill="#001d42" />
    </svg>
  );
};